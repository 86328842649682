import kebabCase from 'lodash/kebabCase'
import store from '@/store'
import { state } from '@/store/modules/researcher/integrations'
import notifier from '@/utils/notifier'

export const appsAndIntegrationUrl = 'https://partners.prolific.com/marketplace'

export default [
  {
    path: '/researcher/integrations',
    name: 'integrations',
    beforeEnter: (_to, _from, next) => {
      window.open(appsAndIntegrationUrl, '_blank', 'noopener noreferrer')
      return next({ path: '/researcher/home' })
    },
  },
  {
    path: '/researcher/integrations/details/:id',
    name: 'integration-details',
    beforeEnter: (to, _from, next) => {
      const integration = state.integrations.find(
        integration => integration.id === parseInt(to.params.id)
      )

      if (!integration) {
        window.open(appsAndIntegrationUrl, '_blank', 'noopener noreferrer')
        return next({ path: '/researcher/home' })
      }

      const title = kebabCase(integration.title)
      window.open(
        `${appsAndIntegrationUrl}/${title}`,
        '_blank',
        'noopener noreferrer'
      )
      return next({ path: '/researcher/home' })
    },
  },
  {
    path: '/researcher/integrations/tokens',
    name: 'integrations.tokens',
    beforeEnter: (_to, _from, next) => {
      const workspaceId = store.getters['researcher/workspaces/workspace']?.id
      notifier.info(
        'Integration tokens have moved to the settings page of your workspace.'
      )
      return next({
        path: 'researcher/workspaces/:workspaceId/settings/tokens',
        name: 'workspaces.settings.tokens',
        params: { workspaceId },
      })
    },
  },
  {
    path: '/researcher/integrations/tokens/create',
    name: 'integrations.token.create',
    beforeEnter: (_to, _from, next) => {
      const workspaceId = store.getters['researcher/workspaces/workspace']?.id
      notifier.info(
        'Integration tokens have moved to the settings page of your workspace.'
      )
      return next({
        path: 'researcher/workspaces/:workspaceId/settings/tokens/create',
        name: 'workspaces.settings.token.create',
        params: { workspaceId },
      })
    },
  },
]
