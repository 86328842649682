import { ref } from 'vue'
import useAnalytics from './useAnalytics'

export default () => {
  const shouldShowModal = ref(false)
  const isLoading = ref(false)

  const { trackEvent, Label } = useAnalytics()
  const handleModalAction = (value: boolean) => {
    shouldShowModal.value = value
    trackEvent(value ? Label.OPEN_MODAL : Label.CLOSE_MODAL)
  }

  return {
    shouldShowModal,
    isLoading,
    handleModalOpen: () => handleModalAction(true),
    handleModalClose: () => handleModalAction(false),
  }
}
