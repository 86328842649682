import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import notifier from '@/utils/notifier'
import { createEnrollmentTicket } from '../utils'
import useAnalytics from './useAnalytics'

const PROVIDER_PREFIX = /^(auth0|google-oauth2)\|/

export default () => {
  const { getters } = useStore()

  const isLoading = ref(false)
  const accessToken = computed(() => getters['oidc/oidcAccessToken'])
  const oidcUser = computed(() => getters['oidc/oidcUser'])

  const { trackEvent, Label } = useAnalytics()

  const handlePanelEnable = async () => {
    isLoading.value = true
    try {
      const oidcUserId = oidcUser?.value?.sub?.replace(PROVIDER_PREFIX, '')
      const ticketUrl = await createEnrollmentTicket(
        oidcUserId,
        accessToken.value
      )

      if (ticketUrl) {
        window.location.href = ticketUrl
      } else {
        notifier.error(
          "Sorry, we're experiencing some issues. Please try again. If the problem persists, please contact support."
        )
      }

      trackEvent(Label.ENABLE)
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    handlePanelEnable,
  }
}
