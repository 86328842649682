<template>
  <DSDialog
    v-model:open="isVisible"
    :show-close-button="true"
    :should-close-on-outside-click="true"
    @close="handleModalClose"
  >
    <template #default="panelProps">
      <AccountProtectionPanel
        :panel-props="panelProps"
        @close="handleModalClose"
      />
    </template>
  </DSDialog>
</template>

<script setup lang="ts">
import { DSDialog } from '@prolific-oss/design-system'
import { ref } from 'vue'
import AccountProtectionPanel from './AccountProtectionPanel.vue'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const isVisible = ref(true)
const handleModalClose = () => emit('close')
</script>
